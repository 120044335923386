import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	ChangeOrderStatusDto,
	CreateOrderQuoteDto,
	OrderEntity,
} from "@rototip/lib-order";

const urlPrefix = getIsServer() ? process.env.SVC_ORDER_URL : "";

export const orderStateTransitionApi = createApi({
	reducerPath: "api:order-state",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/order/state-transition`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		// user actions
		submitRfq: builder.mutation<OrderEntity, [string, string]>({
			query: ([orderId, versionId]) => ({
				url: `/${orderId}/${versionId}/submit-rfq`,
				method: "PATCH",
			}),
		}),
		cancelOrder: builder.mutation<
			OrderEntity,
			[[string, string], ChangeOrderStatusDto]
		>({
			query: ([[orderId, versionId], cancelDto]) => ({
				url: `/${orderId}/${versionId}/cancel`,
				method: "PATCH",
				body: cancelDto,
			}),
		}),
		declineQuote: builder.mutation<
			OrderEntity,
			[[string, string], ChangeOrderStatusDto]
		>({
			query: ([[orderId, versionId], declineDto]) => ({
				url: `/${orderId}/${versionId}/decline-quote`,
				method: "PATCH",
				body: declineDto,
			}),
		}),
		approveQuote: builder.mutation<
			OrderEntity,
			[[string, string], ChangeOrderStatusDto]
		>({
			query: ([[orderId, versionId], body]) => ({
				url: `/${orderId}/${versionId}/approve-quote`,
				method: "PATCH",
				body,
			}),
		}),
		requestCallback: builder.mutation<
			OrderEntity,
			[[string, string], ChangeOrderStatusDto]
		>({
			query: ([[orderId, versionId], callbackDto]) => ({
				url: `/${orderId}/${versionId}/request-callback`,
				method: "PATCH",
				body: callbackDto,
			}),
		}),
		// staff actions
		rejectOrder: builder.mutation<
			OrderEntity,
			[[string, string], ChangeOrderStatusDto]
		>({
			query: ([[orderId, versionId], cancelDto]) => ({
				url: `/${orderId}/${versionId}/reject`,
				method: "PATCH",
				body: cancelDto,
			}),
		}),
		quoteOrder: builder.mutation<
			OrderEntity,
			[[string, string], CreateOrderQuoteDto]
		>({
			query: ([[orderId, versionId], body]) => {
				return {
					url: `/${orderId}/${versionId}/quote`,
					method: "PATCH",
					body,
				};
			},
		}),
		archiveOrder: builder.mutation<OrderEntity, [string, string]>({
			query: ([orderId, versionId]) => ({
				url: `/${orderId}/${versionId}/archive`,
				method: "PATCH",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useSubmitRfqMutation,
	useCancelOrderMutation,
	useRejectOrderMutation,
	useQuoteOrderMutation,
	useDeclineQuoteMutation,
	useRequestCallbackMutation,
	useArchiveOrderMutation,
	useApproveQuoteMutation,
} = orderStateTransitionApi;

// export endpoints for use in SSR and with form manager
export const getOrderStateTransitionApiPromises =
	orderStateTransitionApi.util.getRunningQueriesThunk;
export const {
	cancelOrder,
	submitRfq,
	quoteOrder,
	rejectOrder,
	approveQuote,
	declineQuote,
	requestCallback,
} = orderStateTransitionApi.endpoints;
