import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type GlobalDefaultsForVersion = {
	profitMarginPercentGlobalDefault: number;
	shippingCostsGlobalDefault: number;
	packagingCostsGlobalDefault: number;
	totalOrderWeight: number;
};

export type GlobalDefaultsState = {
	[versionId: string]: GlobalDefaultsForVersion;
};

const initialState: GlobalDefaultsState = {};

export const globalDefaultsSlice = createSlice({
	name: "system:globalDefaults",
	initialState,
	reducers: {
		setGlobalDefaults(
			state,
			action: PayloadAction<{
				versionId: string;
				data: Partial<GlobalDefaultsState[string]>;
			}>
		) {
			const { versionId, data } = action.payload;
			if (!state[versionId]) {
				state[versionId] = {
					profitMarginPercentGlobalDefault: 72,
					shippingCostsGlobalDefault: 0,
					packagingCostsGlobalDefault: 0,
					totalOrderWeight: 1,
				};
			}
			state[versionId] = { ...state[versionId], ...data };
		},
		removeGlobalDefaultsForVersion(
			state,
			action: PayloadAction<{ versionId: string }>
		) {
			const { versionId } = action.payload;
			if (state[versionId]) {
				delete state[versionId];
			}
		},
	},
});

export const { setGlobalDefaults, removeGlobalDefaultsForVersion } =
	globalDefaultsSlice.actions;
export default globalDefaultsSlice.reducer;

// Base selector for the entire global defaults state
export const selectGlobalDefaults = (state: RootState) =>
	state["system:globalDefaults"];

// Selector factory for a given versionId
export const selectGlobalDefaultsForVersion = (versionId: string) =>
	createSelector(selectGlobalDefaults, (globalDefaults) => {
		return globalDefaults[versionId] || undefined;
	});
