import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { OrderVersionEntity, UpdateOrderVersionDto } from "@rototip/lib-order";
import { HYDRATE } from "next-redux-wrapper";
import { orderApi } from "../orderApi";
import { rfqApi } from "../rfqApi";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_ORDER_URL : "";

export const orderVersionApi = createApi({
	tagTypes: ["orderVersion"],
	reducerPath: "api:order-versions",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/order/order-version`,
		prepareHeaders,
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		updateOrderVersion: builder.mutation<
			OrderVersionEntity,
			[string, UpdateOrderVersionDto]
		>({
			query: ([orderVersionId, dto]) => ({
				url: `/${orderVersionId}`,
				method: "PATCH",
				body: dto,
			}),
			async onQueryStarted([id, dto], { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(orderApi.util.invalidateTags(["order"]));
					dispatch(rfqApi.util.invalidateTags(["rfq"]));
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
		versionOrder: builder.mutation<
			OrderVersionEntity,
			[
				string,
				{
					orderVersionId: string;
					versionNumber: number;
					comment: string;
					makePrimary: boolean;
				},
			]
		>({
			query: ([orderId, orderVersionDto]) => ({
				url: `/${orderId}/${orderVersionDto.orderVersionId}/version`,
				method: "PATCH",
				body: orderVersionDto,
			}),
			async onQueryStarted([id, dto], { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(orderApi.util.invalidateTags(["order"]));
					dispatch(rfqApi.util.invalidateTags(["rfq"]));
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),

		archiveOrderVersionDraft: builder.mutation<
			OrderVersionEntity,
			[string, string]
		>({
			query: ([orderId, orderVersionId]) => ({
				url: `/${orderId}/${orderVersionId}/archive`,
				method: "PATCH",
			}),
			async onQueryStarted([id, dto], { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(orderApi.util.invalidateTags(["order"]));
					dispatch(rfqApi.util.invalidateTags(["rfq"]));
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
		makeVersionPrimary: builder.mutation<OrderVersionEntity, [string, string]>({
			query: ([orderId, orderVersionId]) => ({
				url: `/${orderId}/${orderVersionId}/make-primary`,
				method: "PATCH",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useUpdateOrderVersionMutation,
	useVersionOrderMutation,
	useArchiveOrderVersionDraftMutation,
	useMakeVersionPrimaryMutation,
} = orderVersionApi;
export const getOrderVersionApiPromises =
	orderVersionApi.util.getRunningQueriesThunk;

// export endpoints for use in SSR
export const {
	updateOrderVersion,
	versionOrder,
	archiveOrderVersionDraft,
	makeVersionPrimary,
} = orderVersionApi.endpoints;
